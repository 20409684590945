<template>
<v-dialog v-model="$store.state.subjects.forms.view_list_items" max-width="800">
  <v-card :loading="loading">
    <v-card-title>
      <v-icon>mdi-list-box-outline</v-icon>
      <span class="mr-2">عرض مواد الطلب</span>
    </v-card-title>
    <v-divider/>
    <v-card-text>
      <v-simple-table>
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-center">ت</th>
            <th class="text-center">اسم المادة</th>
            <th class="text-center">العدد المطلوب</th>
            <th class="text-center">العدد الممنوح</th>
            <th class="text-center">العدد المتوفر</th>
          </tr>
          </thead>
          <tbody v-if="!loading">
          <tr v-for="(item , i) in list_items" :key="'list_item_i_'+i">
            <td class="text-center">{{list_items.indexOf(item)+1}}</td>
            <td class="text-center">{{item.name_subject}}</td>
            <td class="text-center">
              <span>{{item.count}}</span>
            </td>
            <td class="text-center">
              <v-text-field  v-model="item.accept_count"></v-text-field>
            </td>

            <td class="text-center">
              <v-chip v-if="parseInt(item.subject_count) <= parseInt(item.accept_count)" color="error">{{item.subject_count}}</v-chip>
              <span v-if="parseInt(item.subject_count) > parseInt(item.accept_count) || parseInt(item.accept_count) == 0 ||item.accept_count == '' || item.accept_count ==''">{{item.subject_count}}</span>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
    <v-divider/>
    <v-card-actions>
      <v-btn outlined  @click="$store.state.subjects.forms.view_list_items=false">
        <v-icon v-if="!$vuetify.breakpoint.xs">mdi-close</v-icon>
        <span class="mr-2">غلق</span>
      </v-btn>

      <v-spacer v-if="!$vuetify.breakpoint.xs"/>
      <v-btn v-if="list_state != 1" @click="accept_request"  color="success" dark :width="!$vuetify.breakpoint.xs?'150':'100'" >
        <v-icon v-if="!$vuetify.breakpoint.xs">mdi-check</v-icon>
        <span class="mr-2">قبول وطباعة</span>
      </v-btn>
      <v-btn color="error" @click="reject_request" dark >
        <v-icon v-if="!$vuetify.breakpoint.xs">mdi-close</v-icon>
        <span class="mr-2">رفض</span>
      </v-btn>
      <v-btn color="indigo" @click="reset_request" dark >
        <v-icon v-if="!$vuetify.breakpoint.xs">mdi-reload</v-icon>
        <span class="mr-2">اعادة</span>
      </v-btn>


    </v-card-actions>
  </v-card>
</v-dialog>
</template>

<script>
import vuetify from "@/plugins/vuetify";
import {mapActions} from "vuex";

export default {
  name: "ViewMylistItems",
  data(){
    return{
      list_items:this.$store.state.subjects.list_items,
      list_items_to_print_with_no_clear_after_save:this.$store.state.subjects.list_items,
      list_id:0,
      loading:false,
      list_state:0,
      list:null
    }
  },
  methods:{
    ...mapActions(['print_request']),
    vuetify() {
      return vuetify
    },
    get_list_items()
    {
      this.loading = true;
      this.$axios.post('/api/lists/get-list-items',{list_id:this.list_id}).then(res=>{
        this.list_items = res.data;
      }).catch(err=>{
        console.log(err)
      }).finally(fin=>{
        this.loading = false;
      })
    },
    accept_request()
    {
      this.$confirm("في حال الضغط على قبول سيؤدي الى قطع المواد من المخزن ؟").then(() => {

        if (this.list_id != 0)
        {

          this.loading = true;
          var zero = false;
          var mins = false;

          this.list_items.map(i=>{
            if (parseInt(i.accept_count) < 0) {

              mins = true;
            }
            if (parseInt(i.accept_count) ===0) {

              zero = true;
            }

          })

          if (mins){
            this.$fire({
              title: "عفواً",
              text: 'لايمكن ادخال الرقم بالسالب',
              type: "warning",
              timer: 3000
            });
            return 0;
          }
          if (zero)
          {
            this.$confirm("هنالك مواد لم تقم بتجهيزها هل تريد الاستمرار ؟").then(() => {
              this.save_request();
              this.print_request({list_items:this.list_items,list:this.list});
              this.$router.push('print-request');



            }).catch(no=>{

              this.loading = false;
            })
          }else
          {
            this.save_request();
            this.print_request({list_items:this.list_items,list:this.list});
            this.$router.push('print-request');
          }

        }

        }).catch(err=>{

        }).finally(fin=>{
          this.loading = false;
        })



    },
    save_request()
    {
      this.$axios.post('/api/lists/accept-list-items',{list_id:this.list_id,list_items:this.list_items}).then(res=>{
        this.$store.state.requests.reload++;
        this.get_list_items();
        this.$store.state.subjects.list_target = null;
        this.$store.state.subjects.forms.view_list_items = false;
      }).catch(err=>{
        console.log(err)
      }).finally(fin=>{
        this.loading = false;
      })
    },
    reset_request()
    {

      if (this.list_id != 0)
      {
        this.loading = true;
        this.$axios.post('/api/lists/reset-request',{list_id:this.list_id,list_state:2}).then(res=>{


          this.$store.state.requests.reload++;
          this.get_list_items();
           this.$store.state.subjects.list_target = null;
           this.$store.state.subjects.forms.view_list_items = false;

        }).catch(err=>{
          this.$fire({
            title: "فشل",
            text: err.response.data.msg,
            type: "warning",
            timer: 3000
          })
        }).finally(fin=>{
          this.loading = false;
        })
      }


    },
    reject_request()
    {
      if (this.list_id != 0)
      {
        this.loading = true;
        this.$axios.post('/api/lists/reject-request',{list_id:this.list_id,list_state:2}).then(res=>{
          this.$store.state.requests.reload++;
          this.get_list_items();


        }).catch(err=>{
          this.$fire({
            title: "فشل",
            text: err.response.data.msg,
            type: "warning",
            timer: 3000
          })
        }).finally(fin=>{
          this.loading = false;
        })
      }


    },
  },


  created()
  {

    this.get_list_items();

  },
  computed:{
    get_list_target:function()
    {
      return this.$store.state.subjects.list_target;
    },
    get_list_items2:function()
    {
      return this.$store.state.subjects.list_items;
    },

  },
  watch:{
    get_list_target:function(new_list)
    {
      try{
        this.list = new_list;
        this.list_id = new_list.list_id;
        this.list_state = new_list.list_state;
        this.get_list_items();
      }catch (e){}

    },
    get_list_items2:function(new_items)
    {
      this.list_items = new_items;
      this.list_items_to_print_with_no_clear_after_save = new_items;
    },
  },

};
</script>

<style scoped>

</style>