import { render, staticRenderFns } from "./ViewMylistItemsWithoutControls.vue?vue&type=template&id=3ec31fdf&scoped=true&"
import script from "./ViewMylistItemsWithoutControls.vue?vue&type=script&lang=js&"
export * from "./ViewMylistItemsWithoutControls.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ec31fdf",
  null
  
)

export default component.exports