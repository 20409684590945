
import Vue from "vue";
import vuetify from "@/plugins/vuetify";
import Drawer from "@/components/Drawer.vue";

export default Vue.extend({
  name: "App",
  components: { Drawer },
  methods: {
    vuetify() {
      return vuetify
    }
  },

  data: () => ({
    //
  }),
  created() {

    this.$store.state.drawer = false;
    //this.$store.commit("GET_ITEMS");
    this.$store.commit("RENDER");


    this.$store.state.users.forms.add_user = false;
    this.$store.state.users.forms.edit_user = false;
    this.$store.state.users.forms.delete_user = false;


    this.$store.state.subjects.forms.add_subject = false;
    this.$store.state.subjects.forms.edit_subject = false;
    this.$store.state.subjects.forms.delete_subject = false;

    this.$store.state.items.forms.add_item = false;
    this.$store.state.items.forms.edit_item = false;
    this.$store.state.items.forms.delete_item = false;

    this.$store.state.units.forms.add_unit = false;
    this.$store.state.units.forms.edit_unit = false;
    this.$store.state.units.forms.delete_unit = false;





  }
});
