import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMain } from 'vuetify/lib/components/VMain';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VApp,[_c('Drawer'),_c(VAppBar,{staticClass:"d-print-none",attrs:{"app":"","color":"primary"}},[(_vm.$store.state.user.login)?_c(VBtn,{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.$store.state.drawer = !_vm.$store.state.drawer}}},[_c(VIcon,[_vm._v("mdi-menu")])],1):_vm._e(),_c(VSpacer),(!_vm.$vuetify.theme.dark)?_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){_vm.$vuetify.theme.dark=true}}},[_c(VIcon,{attrs:{"color":"white"}},[_vm._v("mdi-moon-waxing-crescent")])],1):_vm._e(),(_vm.$vuetify.theme.dark)?_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){_vm.$vuetify.theme.dark=false}}},[_c(VIcon,[_vm._v("mdi-white-balance-sunny")])],1):_vm._e()],1),_c(VMain,[_c('router-view')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }