import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"max-width":"800"},model:{value:(_vm.$store.state.subjects.forms.view_list_items_without_controls),callback:function ($$v) {_vm.$set(_vm.$store.state.subjects.forms, "view_list_items_without_controls", $$v)},expression:"$store.state.subjects.forms.view_list_items_without_controls"}},[_c(VCard,{attrs:{"loading":_vm.loading}},[_c(VCardTitle,[_c(VIcon,[_vm._v("mdi-list-box-outline")]),_c('span',{staticClass:"mr-2"},[_vm._v("عرض مواد الطلب")])],1),_c(VDivider),_c(VCardText,[_c(VSimpleTable,{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-center"},[_vm._v("ت")]),_c('th',{staticClass:"text-center"},[_vm._v("اسم المادة")]),_c('th',{staticClass:"text-center"},[_vm._v("العدد المطلوب")]),_c('th',{staticClass:"text-center"},[_vm._v("العدد الممنوح")]),_c('th',{staticClass:"text-center"},[_vm._v("العدد المتوفر")])])]),(!_vm.loading)?_c('tbody',_vm._l((_vm.list_items),function(item,i){return _c('tr',{key:'list_item_i_'+i},[_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.list_items.indexOf(item)+1))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.name_subject))]),_c('td',{staticClass:"text-center"},[_c('span',[_vm._v(_vm._s(item.count))])]),_c('td',{staticClass:"text-center"},[_c('span',[_vm._v(_vm._s(item.accept_count))])]),_c('td',{staticClass:"text-center"},[_c('span',[_vm._v(_vm._s(item.subject_count))])])])}),0):_vm._e()]},proxy:true}])})],1),_c(VDivider),_c(VCardActions,[_c(VBtn,{attrs:{"outlined":""},on:{"click":function($event){_vm.$store.state.subjects.forms.view_list_items_without_controls=false}}},[(!_vm.$vuetify.breakpoint.xs)?_c(VIcon,[_vm._v("mdi-close")]):_vm._e(),_c('span',{staticClass:"mr-2"},[_vm._v("غلق")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }