<template>
<v-dialog v-model="$store.state.subjects.forms.view_list_items_without_controls" max-width="800">
  <v-card :loading="loading">
    <v-card-title>
      <v-icon>mdi-list-box-outline</v-icon>
      <span class="mr-2">عرض مواد الطلب</span>
    </v-card-title>
    <v-divider/>
    <v-card-text>
      <v-simple-table>
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-center">ت</th>
            <th class="text-center">اسم المادة</th>
            <th class="text-center">العدد المطلوب</th>
            <th class="text-center">العدد الممنوح</th>
            <th class="text-center">العدد المتوفر</th>
          </tr>
          </thead>
          <tbody v-if="!loading">
          <tr v-for="(item , i) in list_items" :key="'list_item_i_'+i">
            <td class="text-center">{{list_items.indexOf(item)+1}}</td>
            <td class="text-center">{{item.name_subject}}</td>
            <td class="text-center">
              <span>{{item.count}}</span>
            </td>
            <td class="text-center">
              <span>{{item.accept_count}}</span>
            </td>

            <td class="text-center">
              <span>{{item.subject_count}}</span>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
    <v-divider/>
    <v-card-actions>
      <v-btn outlined  @click="$store.state.subjects.forms.view_list_items_without_controls=false">
        <v-icon v-if="!$vuetify.breakpoint.xs">mdi-close</v-icon>
        <span class="mr-2">غلق</span>
      </v-btn>




    </v-card-actions>
  </v-card>
</v-dialog>
</template>

<script>
import vuetify from "@/plugins/vuetify";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "ViewMylistItemsWithoutControls",
  data(){
    return{
      list_items:null,
      list_items_to_print_with_no_clear_after_save:this.$store.state.subjects.list_items,
      list_id:0,
      loading:false,
      list_state:0,
      list:null
    }
  },
  methods:{
    vuetify() {
      return vuetify
    },
    get_list_items()
    {
      this.loading = true;
      this.$axios.post('/api/lists/get-list-items',{list_id:this.list_id}).then(res=>{
        this.list_items = res.data;
      }).catch(err=>{
        console.log(err)
      }).finally(fin=>{
        this.loading = false;
      })
    },


  },


  created()
  {


  },
  computed:{
    get_list_target()
    {
      return this.$store.state.subjects.list_target;
    },
  },
  watch:{
    get_list_target(new_list)
    {
      this.list_id = new_list.list_id;
      this.get_list_items();
    },
  },


};
</script>

<style scoped>

</style>