import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"max-width":"500"},model:{value:(_vm.$store.state.subjects.forms.view_image),callback:function ($$v) {_vm.$set(_vm.$store.state.subjects.forms, "view_image", $$v)},expression:"$store.state.subjects.forms.view_image"}},[_c(VCard,[_c(VCardTitle,[_vm._v(" "+_vm._s(_vm.subject.name_subject)+" ")]),_c(VDivider),_c(VCardText,[_c(VImg,{attrs:{"src":_vm.subject.image}})],1),_c(VDivider),_c(VCardActions,[_c(VBtn,{attrs:{"color":"grey","dark":""},on:{"click":function($event){_vm.$store.state.subjects.forms.view_image=false}}},[_c(VIcon,[_vm._v("mdi-close")]),_c('span',{staticClass:"mr-2"},[_vm._v("الغاء")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }