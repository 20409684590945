

<template>
  <div>

    <div class="text-center" style="width: 100%;border: 1px solid black;background-color: #3a3939">
      <span class="ma-auto" style="color: white"> الفرع:  {{list_to_print.to}} / الاسم: {{list_to_print.name}}</span>
    </div>
    <div  class="text-center" style="width: 100%;border: 1px solid black;background-color: #797979">

      <div class="ma-auto" style="color: white">
        تاريخ الطلب: {{list_to_print.created_at.substring(0,10)}}    /    عدد المواد في الطلب : {{list_to_print.count_items}}
    </div>
      <v-btn color="blue" dark @click="print" class="text-left d-print-none">
        <v-icon dark>mdi-printer</v-icon>
      </v-btn>
    </div>


    <table class="print text-center ma-auto"  style="width: 100%;margin: 10px">
      <thead>
      <tr>
        <th class="text-center">ت</th>
        <th class="text-center">اسم المادة</th>
        <th class="text-center">العدد المطلوب</th>
        <th class="text-center">العدد الممنوح</th>

      </tr>


      </thead>
      <tbody>
      <tr v-for="(item ,i) in items_to_print" :key="'list__'+i">
        <td class="text-center">{{items_to_print.indexOf(item)+1}}</td>
        <td class="text-center">{{item.name_subject}}</td>
        <td class="text-center" >{{item.count}}</td>
        <td class="text-center">{{item.accept_count}}</td>



      </tr>
      </tbody>
    </table>
</div>
</template>
<script>

import {mapGetters} from "vuex";

export default {
  name:'',

  data(){
    return{
list_items:[]
    }
  },
  methods:{
    print(){
      window.print();
    }
  },
  mounted() {

  },
  computed: {
    ...mapGetters(['print_request']),
    items_to_print() {
      return this.print_request.list_items;
    },
    list_to_print()
    {
      return this.print_request.list;
    }
  }

}
</script>
<style scoped>
.print{
  direction: rtl !important;
}
table ,tr, td, th {
  border: 1px solid black !important;
  border-collapse: collapse;
}
</style>